import { styled } from "linaria/react";

export const Column = styled.div`
  flex-basis: 25%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.25rem;
  position: relative;
  font-size: 18px;
  &:first-of-type {
    width: 350px;
    align-items: flex-start;
    text-align: left;
    @media (max-width: 1024px) {
      flex-basis: 33%;
      text-align: center;
      align-items: center;
    }
  }
  @media (max-width: 1024px) {
    flex-basis: 33%;
    font-size: 16px;
  }
`;
