import { useState, useEffect } from "react";
import useIsMobile from "./useIsMobile";

function useStickyElementAtTop(elementRef) {
  const { isMobile } = useIsMobile(820);
  const [isClient, setIsClient] = useState(false);
  const [isStickyAtTop, setIsStickyAtTop] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;
    function isElementAtTop() {
      if (!elementRef.current) return false;
      const rect = elementRef.current.getBoundingClientRect();
      const topOffset = isMobile ? window.innerHeight * 0.1 : 80;
      return rect.top <= topOffset;
    }

    function handleScroll() {
      setIsStickyAtTop(isElementAtTop());
    }

    function handleTouch() {
      setIsStickyAtTop(isElementAtTop());
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleTouch);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleTouch);
    };
  }, [isClient, isMobile]);

  return isStickyAtTop;
}

export default useStickyElementAtTop;
