import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React, { useMemo, useRef } from "react";
import SelectorCol from "./SelectorCol";
import useIsMobile from "hooks/useIsMobile";
import { Column } from "./styles";
import useStickyElementAtTop from "hooks/useStickyElementAtTop";

const SelectorHeaderContainer = styled(SectionContainer)`
  background-color: var(--nuetral-100);
  transition: all 250ms;
  padding-top: ${props => (!props.stickyActive ? "5rem" : "1.25rem")};
  padding-bottom: ${props => (!props.stickyActive ? "5rem" : "1.25rem")};
  overflow: visible;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  z-index: 3;
  @media (max-width: 1024px) {
    background-color: var(--white);
  }
`;

const SelectorHeaderInner = styled(SectionInner)`
  flex-direction: row;
  .title-col {
    text-align: left;
  }
  @media (max-width: 1024px) {
    gap: 1.25rem;
    flex-wrap: wrap;
    .title-col {
      flex-basis: 100%;
      justify-content: center;
      text-align: center;
    }
  }
`;

const SelectorTitle = styled(FancyTitle)`
  font-size: 32px;
  min-height: 54px;
  display: flex;
  align-items: center;
`;

const SelectorHeader = ({
  deviceData,
  title,
  selectedDevice1,
  setSelectedDevice1,
  selectedDevice2,
  setSelectedDevice2,
  selectedDevice3,
  setSelectedDevice3,
}) => {
  const stickyRef = useRef(null);

  const stickyActive = useStickyElementAtTop(stickyRef);
  const { isMobile } = useIsMobile(1024);
  const allSelectedDevices = useMemo(() => {
    return [selectedDevice1, selectedDevice2, selectedDevice3];
  }, [selectedDevice1, selectedDevice2, selectedDevice3]);

  const compareText = isMobile
    ? "Compare 2 devices side-by-side"
    : "Compare up to 3 devices side-by-side";

  return (
    <SelectorHeaderContainer
      stickyActive={stickyActive}
      id="compare"
      ref={stickyRef}
    >
      <SelectorHeaderInner>
        <Column className="title-col">
          {!stickyActive ? (
            <TextContainer>
              <SelectorTitle>{title}</SelectorTitle>
              <MainParagraph>{compareText}</MainParagraph>
            </TextContainer>
          ) : (
            <>{!isMobile && <SelectorTitle>{title}</SelectorTitle>}</>
          )}
        </Column>

        <SelectorCol
          data={deviceData}
          selectedDevice={selectedDevice1}
          setSelectedDevice={setSelectedDevice1}
          stickyActive={stickyActive}
          allSelectedDevices={allSelectedDevices}
        />

        <SelectorCol
          data={deviceData}
          selectedDevice={selectedDevice2}
          setSelectedDevice={setSelectedDevice2}
          stickyActive={stickyActive}
          allSelectedDevices={allSelectedDevices}
        />
        {!isMobile && (
          <SelectorCol
            data={deviceData}
            selectedDevice={selectedDevice3}
            setSelectedDevice={setSelectedDevice3}
            stickyActive={stickyActive}
            allSelectedDevices={allSelectedDevices}
          />
        )}
      </SelectorHeaderInner>
    </SelectorHeaderContainer>
  );
};

export default SelectorHeader;
