import Features from "components/common/Features";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import CompareDevices from "components/compare-devices/CompareDevices";
import { CAMERAS } from "components/compare-devices/camera-data";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

export default function CompareCamerasPage() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "compare cameras",
      title: "Find the Right Camera for Your Unique Environment",
      description:
        "Easily compare Rhombus cameras to make an informed decision.",
      image: data.headerImage,
    };

    const features = [
      {
        icon: data.icon1,
        iconAlt: "Secure by Default",
        title: "Secure by Default",
        p:
          "With enterprise-grade encryption and regular security audits, Rhombus puts your security first.",
      },
      {
        icon: data.icon2,
        iconAlt: "Simple Setup",
        title: "Simple Setup",
        p:
          "No NVR/DVRs required. Rhombus cameras connect via PoE and come online in minutes. ",
      },
      {
        icon: data.icon3,
        iconAlt: "Built with Quality in Mind",
        title: "Built with Quality in Mind",
        p:
          "All cameras use enterprise-grade components and include 10-year warranties for complete peace-of-mind.",
      },
      {
        icon: data.icon4,
        iconAlt: "Automatic Updates",
        title: "Automatic Updates",
        p:
          "Stay ahead of threats and protect your organization with the best security technology.",
      },
    ];

    return (
      <>
        <HeroLayout1 data={headerSection} bottom minHeight="550px" noGap />
        <CompareDevices deviceData={CAMERAS} title="Compare Cameras" />
        <Features
          color="var(--nuetral-100)"
          data={features}
          title="Camera Features"
        />
        <TrialBanner />
      </>
    );
  };

  const GET_IMAGES = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/cameras/img/cameras-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/plug.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/industries/img/icons/certification.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
