export const DEVICE_MAP = {
  "R120 Mini": "r120",
  "R170 Micro": "r170",
  "R200 Mini": "r200",
  "R230 WiFi": "r230",
  "R400 Dome": "r400",
  "R360 Fisheye": "r360",
  "R500 Telephoto": "r500",
  "R510 Standard Zoom": "r510",
  "R600 Multisensor": "r600",
};
