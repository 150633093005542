import React from "react";

import CompareCameras from "../../components/cameras/compare-cameras/CompareCamerasPage";
import GlobalLayout from "../../components/page/GlobalLayout";
import Helmet from "react-helmet";

export default function CompareCamerasPage() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Compare Rhombus Cameras - The New Standard in Security</title>
        <meta
          name="description"
          content="Learn about the different Rhombus camera models and hardware specs. Find the right business security camera for your organization."
        />
      </Helmet>
      <CompareCameras />
    </GlobalLayout>
  );
}
