import { useEffect, useRef } from "react";

export const useOutsideClick = callBack => {
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [callBack]);
  return ref;
};
